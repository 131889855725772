/**
 * Query keys for react-query's useQuery.
 * These keys must be unique across the entire application, so be sure to
 * namespace them appropriately.
 */
export const queryKeys = {
  session: 'session',
  activities: {
    index: 'activities-index',
  },
  annotations: {
    indexReadingOrder: 'annotations-index-reading-order',
    index: 'annotations-index',
    show: 'annotations-show',
    ids: 'annotation-ids',
    byIds: 'annotation-by-ids',
  },
  book: {
    index: 'books-index',
    show: 'books-show',
  },
  bookCircles: {
    show: 'bookCircles-show',
  },
  bookCircleFeed: {
    annotations: 'bookCircleFeed-annotations',
    replies: 'bookCircleFeed-replies',
  },
  circles: {
    index: 'circles-index',
    paginatedUsers: 'circles-paginated-users',
    search: 'circles-search',
    show: 'circles-show',
    userCircles: 'circles-user-circles',
    publicShow: 'public-circles-show',
  },
  circleCollections: {
    index: 'circleCollections-index',
  },
  circlePosts: {
    index: 'circlePosts-index',
  },
  documentProfiles: {
    index: 'document-profiles-index',
    show: 'document-profiles-show',
    search: 'document-profiles-search',
    circles: 'document-profiles-circles',
    replies: 'document-profiles-replies',
    threads: 'document-profiles-threads',
    trending: 'document-profiles-trending',
    recommended: 'document-profiles-recommended',
    featured: 'document-profiles-featured',
    user: 'document-profiles-user',
    genre: 'document-profiles-genre',
    books: 'document-profiles-books',
    requests: 'document-profiles-requests',
    feed: 'document-profiles-feed',
    hasReadingProgress: 'document-profiles-has-reading-progress',
    readers: 'document-profiles-readers',
  },
  notifications: {
    index: 'notifications-index',
  },
  readingProgresses: {
    jumpBackIn: 'readingProgresses-jump-back-in',
    session: 'readingProgresses-session',
  },
  featureFlags: {
    index: 'feature-flags-index',
  },
  follows: {
    following: 'following',
    followersByUserIds: 'followers-by-user-ids',
  },
  users: {
    search: 'users-search',
    show: 'users-show',
    index: 'users-index',
    trending: 'users-trending',
  },
  replies: {
    nested: 'replies-nested',
    grouped: 'replies-grouped',
    show: 'replies-show',
    targeted: 'replies-targeted',
  },
  genres: {
    index: 'genres-index',
    favorites: 'genres-favorites',
  },
};
